.itemscontainer, .infos{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

}

.itemscontainer .item, .user .infos .info{
    margin-top: 15px;
    background-color: var(--block-bg-color);
    border: 3px solid var(--main-color);
    width: fit-content;
    padding: 25px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s;
}


.itemscontainer .item{
    position: relative;
}
.itemscontainer .item:hover > div{
    opacity: 1;
}
.itemscontainer .item > div{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;    
    border-radius: 10px;
    background: rgba(24, 24, 27, 0.92);
    opacity: 0;
    transition: 0.3s;
}
.itemscontainer .item:hover,.user .infos .info:hover{
    transform: translate3d(0px, 0px, 0px) scale3d(1.03, 1.03, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}
.hidded-form{
    display: flex;
    flex-direction: column;
    gap: 50px;
    visibility: none;
    height: 0px;
    opacity: 0;
    overflow-y: hidden;
    transition:  0.3s;
}
.hidded-form.display{
    margin-top: 50px;
    visibility: visible;
    height: fit-content;
    opacity: 1;
    margin-bottom: 10px;
}
