.contact-container{
    display: flex;
    flex-direction: row;
}

.contact form {
    width: 100%;

}
 form .fields{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

form .field{
    display: flex;
    flex-direction: column;
}
form .fields input,  form .fields textarea, select{
    width: 100%;
    background-color: var(--block-bg-color);
    border: 1px solid var(--border-color);
    padding: 10px;
    box-sizing: border-box;
    padding-left: 2%;
    padding-right: 0;
    resize: none;
}

form .fields textarea{
    height: 150px;
    margin-top: 10px;
    padding: 2%;
}

.contact form button{
    width: fit-content;
    margin-top: 20px;
}


@media  (max-width: 680px) {
    .contact .medias{
        display: none;
    }
}

.PhoneInput{
    width: 100%;
    background-color: var(--block-bg-color);
    border: 1px solid var(--border-color);
    padding: 10px;
    box-sizing: border-box;
    padding-left: 2%;
    padding-right: 0;
    resize: none;
}

.PhoneInput input{

padding: 0 !important;
border: 0 !important;}