.extraitbloc{
    margin-top: 80px;
    flex-wrap: wrap;
    
}

.extraitbloc .leftfragment{

    width: 45%;
    margin: auto;
}

.extraitbloc .leftfragment p{
    width: 100%;
}
.extraitcontainer{
    width: clamp(350px, 35vw, 550px);
    height: clamp(500px, 50vw, 800px);
    background: #000000;
    border-radius: 15px;
    border: 10px solid var(--main-color);
    overflow: scroll;
    overflow-x: hidden;
    margin: auto;
    position: relative;
    transition: 0.2s;

}

.rightfragment:hover .pdfinput{
    opacity: 1;
}

.pdfinput{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    opacity: 0;
    background-color: rgba(28, 28, 31, 0.72);
    z-index: 1;
    border-radius: 50px;    
    transition: all 0.3s;
    cursor: pointer;


}
.pdfinput img{
    width: 12px;
    height: auto;
}

.previouspage{
    left: 20px;
}
.nextpage{
    right: 20px;
}

.nextpage img{
    transform: rotate(180deg);
}

.disclaimer-free{
    position:absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: rgba(14, 14, 16, 0.58);
bottom:  -100% !important; 
   left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.blur{
    filter: blur(2px);
    
}
.disclaimer-free button{
    margin: 0;
}
.disclaimer-free > div{
    width: 85%;
    position: relative;
    bottom: 0;
    
}
@media (max-width: 1215px) {
  .extraitbloc .rightfragment{
  
    margin: auto;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: 50px;
  }
 

  .extraitbloc .leftfragment{

    width: 100%;
    
}
}


@media (max-width: 420px) {
 .extraitcontainer{
        width: 100% !important;
    }

}