.topScreen{
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--block-bg-color);
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: fit-content;
    padding: 10px 0;
    border: 1px solid var(--border-color);

}
.topScreen .content {
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
}
.topScreen .content span{
    color: var(--text-color);
    font-size: clamp(0.8rem, 1vw + .1rem, 1.1rem);

}   
.topScreen > .content *{
    font-size: clamp(0.8rem, 1vw + .1rem, 1.1rem);
}

@media (max-width: 680px) {
    .topScreen{
        width: 100%;
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        height: auto;
        padding: 10px 0;
    } nav h1{
        margin-left: 20px;
      }
 
nav{
    width: 100%;

}
}