#button{
    width: 25px;
    display: none;
   
    position: relative;
    cursor: pointer;
    margin-right: 25px;
}

#button > span{
    display: block;
    width: 100%;
    height: 3px;
    background-color: var(--text-color);
    border-radius: 3px;
    transition: all .4s;
    position: relative;
}
@media (max-width: 920px) {
    #button{
        display: block;
      }
    

}  
#button span:not(:first-child){
    margin-top: 5px;

}

#button.active span:nth-child(1) {
    animation: ease .4s top forwards;
}

#button span:nth-child(1) {
    animation: ease .4s top-2 forwards;
}

#button.active span:nth-child(2) {
    animation: ease .4s scaled forwards;
}

#button span:nth-child(2) {
    animation: ease .4s scaled-2 forwards;
    background: #fff;
}

#button.active span:nth-child(3) {
    animation: ease .4s bottom forwards;
    background: #fff;
}

#button span:nth-child(3) {
    animation: ease .4s bottom-2 forwards;
}

@keyframes top {
    0% {
        top: 0;
        transform: rotate(0);
    }
    50% {
        top: 10px;
        transform: rotate(0);
    }
    100% {
        top: 10px;
        transform: rotate(45deg);
    }
}

@keyframes top-2 {
    0% {
        top: 22px;
        transform: rotate(45deg);
    }
    50% {
        top: 22px;
        transform: rotate(0deg);
    }
    100% {
        top: 0;
        transform: rotate(0deg);
    }
}

@keyframes bottom {
    0% {
        bottom: 0;
        transform: rotate(0);
    }
    50% {
        bottom: 7px;
        transform: rotate(0);
    }
    100% {
        bottom: 7px;
        transform: rotate(135deg);
    }
}

@keyframes bottom-2 {
    0% {
        bottom: 19px;
        transform: rotate(135deg);
    }
    50% {
        bottom: 19px;
        transform: rotate(0);
    }
    100% {
        bottom: 0;
        transform: rotate(0);
    }
}

@keyframes scaled {
    50% {
        transform: scale(0);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes scaled-2 {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}