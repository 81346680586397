.bandeau{
    width: 100%;
    overflow: scroll;
    padding: 15px 0;
}
.aviscontainer{
    width: fit-content;
    display: flex;
    flex-direction: row;
    gap: 50px;

}
.avis{
    background-color: var(--block-bg-color);
    border: 1px solid var(--border-color);
    width:  378px;
    height: fit-content;
    overflow: auto;
    border-radius: 15px;
    padding: 5px 15px;
}


.avis p{
    font-size: clamp(0.9rem, 1.0vw + .1rem, 1.1rem);
    opacity: 0.85;
    max-height: 4em; /* Remplacez 3em par la hauteur souhaitée */
  
    height: 50px;
    overflow: auto;
}

.avis-bandeau {
    width: 100%;
}
.avis-bandeau .borderbtn{
    width: fit-content;
    margin-left: auto;
}


.scroller {
    max-width: 100%;
}

.scroller__inner {
    padding-block: 1rem;
    display: flex;
    gap: 1rem;


}

.scroller[data-animated="true"] {
    overflow: hidden;
}

.scroller[data-animated="true"] .scroller__inner {
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll 60s
    var(--_animation-direction, forwards) linear infinite;
}
.scroller[data-animated="true"]:hover .scroller__inner{

}
.scroller[data-direction="right"] {
    --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
    --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
    --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
    --_animation-duration: 60s;
}

@keyframes scroll {
    to {
        transform: translate(calc(-50% - 0.5rem));
    }
}


@media (max-width: 680px) {
    .scroller[data-animated="true"] {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch; /* Améliore le défilement sur des appareils tactiles iOS */

}

.bandeau{
    padding-bottom: 0;
}
.avis-bandeau::after{
    content: "Scroll pour voir les avis →";
    display: block;
}

.scroller[data-animated="true"]::-webkit-scrollbar{
    display: block;
}

    .scroller[data-animated="true"] .scroller__inner{
        animation: none;
    }


}