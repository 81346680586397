footer{
width: 100%;
background-color: var(--block-bg-color);
}

.footercontent, .footer-infos{
    width: 80%;
    padding: 50px 0;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px clamp(50px, 9vw, 300px);
}

.footercontent h3{
    border-bottom: 3px solid var(--main-color);
    padding-bottom: 3px;
}
.footercontent ul{
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px
}
.footercontent ul li {
    position: relative;
}
.footercontent ul li a{
    transition: all 0.3s;

    text-decoration: none;
    color: var(--secondary-text-color);
}

.footer-infos{
   
    justify-content: space-between;
    padding: 0;
    padding-bottom: 10px;
}
.footer-infos h5{
    color: var(--secondary-text-color);
    font-family: "Lexgend-Regular";
    font-weight: 400;
    font-size: 12px;

}

.footersocials{
  
    display: flex;
    flex-direction: column;
    gap: 30px;
}



.footercontent ul li:hover{
    transform:scale(1);
    opacity:1;
    filter:blur(0);
    text-decoration:none;
    color:#fff;
  }
  

  .footercontent ul li:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:var(--main-color);
    transition:0.5s;
    transform-origin:right;
    transform:scaleX(0);
    z-index:-1;
  }
  .footercontent ul li:hover a{
    color: var(--text-color);

  }

  .footercontent ul li:hover:before{
    transition:transform 0.5s;
    transform-origin:left;
    
    transform:scaleX(1);
  }
