.ebook form:not(.free){
    width: 80%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 100px;
   
}

.ebook form:not(.free) .borderbtn{
    width: fit-content;
margin-left: auto;
}
form:not(.free) label{
    display: block;
    font-size: clamp(1rem, 1.1vw + .1rem, 2rem);
    font-family: 'Lexgend-Medium';
    margin-bottom: 15px;
}

.ebook form .fields textarea{
    margin-top: 0;
}

.field .star{
    cursor: pointer;

}
.field .star.hovered svg path{
    fill: var(--main-color);
}

.field.rating{
flex-direction: row;
gap: 20px;
}

@media (max-width: 680px) {

    .ebook form:not(.free){
        width: 100%;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 100px;
       
    }

    .form-container > div{
        width: 90% !important;
    }
}