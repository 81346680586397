.popUp{
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(14, 14, 16, 0.58);
    z-index: 10;
    top: 0;
    left: 0;
    animation: showup 0.3s ease forwards;
    overflow-y: auto;


}

.popUp .content{
    width: 90%;
    margin: auto;
    background-color: var(--block-bg-color);
    border-radius: 10px;
  padding: 20px;
    width: clamp(400px, 60vw, 1000px);
    border-radius: 10px;box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transform: scale(0.9);

}

.popUp .content form{
    width: 95%;
    margin: auto;
    padding: 25px 0;
}
.popUp .content form h3:not(.borderbtn){
    border-bottom: 3px solid var(--main-color);
    padding-bottom: 15px;
}
.popUp .content form {
    gap: 20px;
}

.popUp .extraitcontainer{
    width: clamp(374px, 60vw,474px);
    height:  clamp(581px, 60vh,681px);


}
.popUp span{display: flex;
    align-items: center;
cursor: pointer;
        color: var(--txt-color);
    width: 95%;
    margin: auto;
    padding-top: 25px;
}
.popUp .extraitbloc{
    margin-top: 0;
    padding: 20px;
}
.popUp  .react-pdf__Page__canvas {
    margin:  auto;
    transform: translateX(-10%);
    width: clamp(374px, 60vw,474px) !important;
    height:  clamp(581px, 60vh,681px) !important;
}
@keyframes showup {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
