.iconcontainer{
    display: flex;
    flex-wrap: wrap;
    gap: 35px; border-left: 3px solid var(--main-color);
    padding-left: 20px;
}

 .App >  .iconcontainer{
    position: fixed;
    left: 0;
    top: 50%;
    flex-direction: column;
    transform: translateY(-50%);
    padding-left: 15px;
    gap: 25px;
   
}

 .iconcontainer img{
    width: 20px;
    height: auto;
    padding: 0px;
    transition: 0.2s;
}

.iconcontainer a{
    display: flex;
    justify-content: center;
}

.contact .iconcontainer{
    height: 100%;
    flex-direction: column;
}
.iconcontainer a:hover img{
    opacity: 0.8;
}


.footersocials .iconcontainer{
    gap: 40px;
}

.footersocials .iconcontainer img{
    width: clamp(30px, 3vw, 40px);
    height: auto;
}

.contact .iconcontainer img{
    width: 42px;
    height: auto;
}

@media (max-width: 680px) {
    .App >  .iconcontainer{
        display: none;
    }
}