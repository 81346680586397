

nav h1{
  cursor: pointer;
  font-size: clamp(1.2rem, 2.1vw + .1rem, 1.8rem)
}

nav{

  width: 90%;
  height: var(--nav-height);
  transition: all 0.3s;
  position: fixed;
  top: 0;
  background-color: var(--bg-color);

  z-index: 10;

}
 nav, nav ul{
    display: flex;
    flex-direction: row;
    gap: 60px;
    align-items: center;
}

nav ul {
  width: 100%;
    list-style: none;
    padding-left: 0;

}

nav ul li, nav ul li a, .user-nav.selected svg path{
  transition: all 0.3s;
}

nav ul li a{
  font-size: clamp(0.9rem, 1.0vw + .1rem, 1.1rem);
    font-family: "Lexgend-SemiBold";
    text-decoration: none;
    color: var(--secondary-text-color);
    padding-bottom: 3px;
    user-select: none;
  }

  nav ul li.selected a{
    color: var(--text-color);
    border-bottom: 3px solid var(--main-color);
  }

  .user-nav a{
   
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    text-decoration: none;
    margin-left: auto;
    width:28px;
    transition: all 0.3s;
  
  }
  .user-nav a:hover{
   
     transform:scale(1.1);
  }


  .user-nav.selected svg path{
    fill: var(--main-color);
    fill-opacity: 1;
    
  }
  .user-nav svg{
    width: 100%;
    height: auto;
  }


  nav ul li{
    position: relative;
  }


  nav ul li.hover{
    transform:scale(0.9); 
    opacity:0.8;
    filter:blur(1px);
  }
  
  nav ul li:hover{
    transform:scale(1) !important;
    opacity:1 !important;
    filter:blur(0) !important;
    text-decoration:none !important;
    color:#fff !important;
  }
  

  nav ul li:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:var(--main-color);
    transition:0.5s;
    transform-origin:right;
    transform:scaleX(0);
    z-index:-1;
  }
  
  nav ul li:hover a{
    color: var(--text-color);

  }
 nav ul li:hover:before{
    transition:transform 0.5s;
    transform-origin:left;
    
    transform:scaleX(1);
  }

  @media (min-width : 920px) {
 
 
    
  .nav--hidden {
    transform: translateY(calc(-1 * var(--nav-height)));}

  }

  @media (max-width: 920px) {
    .iconcontainer{
      display: none !important;
    }
    .user-nav {
      margin-left: auto;
    

    }

    .user-nav a{
      width: 22px !important;
    }
    .user-nav a:hover{
      transform:none !important;
   }
nav{
margin: auto;
justify-content: space-between;
height: fit-content;
padding-top: 10px;

}
 
  nav ul{
    display: flex;
    flex-direction: column;
    position: fixed;

    align-items: baseline;
    top: 0;
    height: 100%;
    width: fit-content;
    background-color: var(--block-bg-color);
    padding: 35px;
    right: -100%;
    margin-top: 0;
    padding-top: calc(var(--nav-height) + 50px);
    transition: all 0.3s;
    gap: 40px;
  }

  nav ul.active{
    right: 0px;
  }
  }
  