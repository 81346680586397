.comments-section{
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    width: clamp(320px,60%, 800px);
    margin: auto;
    padding-bottom: 100px;
}

.comments-section .header{
    width: fit-content;
    margin: auto;
    text-align: center;
  
}

.header .rate{
    justify-content: center;
    margin-top: 40px;
}
.header button{
    margin-top: 30px;
}

.comments-container{
    display: flex;
    flex-direction: column;
    gap: 45px;
    margin-top: 50px;
    border-bottom: 3px solid var(--main-color);
    padding-bottom: 25px;
}
.comment span{
    color: rgba(255, 255, 255, 0.62);
    font-family: "Courier";

}

.comment p{
    max-width: 100%;
    font-size: clamp(0.8rem, 1.1vw + .1rem, 1.2rem);
    word-wrap: break-word;
}

.pagination{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 680px) {
    
      .comments-section{
        width: 100%;
      }

      .comments-container{
        margin-top: 100px;
      }
}  