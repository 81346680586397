.google{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #FFFFFF;
    color: black;
    padding: 15px;
    border-radius: 15px;
    cursor: pointer;
    transition:  0.2s;
    text-decoration: none;
}


.google img{
    width: 25px;
    height: 25px;
}
.google:hover{
    opacity: 0.8;
}