.pricebloc span{
    font-size: 1rem;
    color: var(--txt-color);
    position: absolute;
    z-index: 1;
    font-family: "Lexgend-SemiBold";   
}

.pricebloc span ion-icon{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.pricebloc h1{
    margin: 0 ;
}
.pricebloc {
    position: relative;top: -10px;
}
.pricebloc > div{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}
.pricebloc > h1{
    text-decoration: line-through;
    text-decoration-color: rgb(195, 39, 39);
}

.pricebloc > div > div{
    position: relative;
    display: flex;
   position: relative;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
}

.price  ion-icon:not(ion-icon[name="trending-up-outline"]){
    position: absolute;
    transform: rotate(-135deg) ;
    z-index: -1;
    font-size: 75px;
    color: var(--main-color);

}


.price .row2{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

ion-icon[name="trending-up-outline"]{
  
    color: var(--main-color);
    font-size: 30px;
    text-shadow:1px 1px 2px rgba(0, 0, 0, 0.25);
    animation: grind 1.5s infinite alternate-reverse;
}

@keyframes grind {
    from {
        color: var(--main-color);
    }
    to {
        color: white;
    }
}