:root{
  --bg-color : #0e0e10;
  --block-bg-color : #18181b;
  --main-color : #750000;
  --text-color : #FFFFFF;
  --secondary-text-color: rgba(255, 255, 255, 0.62);
  --border-color :rgba(229, 231, 235, 0.25);
}

* {
  font-weight: normal !important;
}



body{
  --nav-height:clamp(45px, 20vw ,90px) ;

}


@font-face {
  font-family: "Lexgend-Bold";
  src: url("../public/fonts/Lexend/static/Lexend-Bold.ttf")format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "Lexgend-SemiBold";
  src: url("../public/fonts/Lexend/static/Lexend-SemiBold.ttf")format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "Lexgend-Medium";
  src: url("../public/fonts/Lexend/static/Lexend-Medium.ttf")format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "Lexgend-Regular";
  src: url("../public/fonts/Lexend/static/Lexend-Regular.ttf")format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "Courier";
  src: url("../public/fonts/Courier_Prime/CourierPrime-Regular.ttf")format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "Courier-Bold";
  src: url("../public/fonts/Courier_Prime/CourierPrime-Bold.ttf")format('truetype');
  font-display: swap;
}



body{
  background-color: var(--bg-color);
}

#root{
  overflow-x : hidden
}

::-webkit-scrollbar {
  width: 16px;
}



::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: hsl(0,0%,57%);
}

*::-webkit-scrollbar-corner { background: rgba(0,0,0,0); }


.App, nav, .footercontent, .footer-infos{
  max-width: 1700px;

}

.App{
  width: 90%;
  margin: auto;
  overflow: hidden;

  padding-top: var(--nav-height);

}

img.product{
  width: clamp(20rem, 36vw, 35rem);

}
header{
  padding: 0 0 10px 0 ;
}

h1{
  font-size: clamp(1.5rem, 2.1vw + .1rem, 1.8rem);
  font-family: "Lexgend-Bold";

}

p, input, textarea, .ebook ul li, select, option{
  font-size: clamp(1rem, 1.22vw + .1rem, 1.2rem);

  font-family: "Courier";
  
}

footer li{
  font-size: clamp(1rem, 1vw + .1rem, 1.2rem);

}

h3{
  font-family: "Lexgend-Medium";
  font-weight: 500;
  font-size: clamp(1.1rem, 1.1vw + .1rem, 1.25rem);

}

input:focus, textarea:focus, select:focus{
  outline: none;
}

*{
  color: var(--text-color);
}

.rowSection{
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.rowSection .leftfragment{
  margin-left: 50px;
}

.rowSection .leftfragment p {
width: 70%;
margin-bottom: 25px;
}

.borderbtn{
  border-left: 3px solid var(--main-color);
  padding-left: 10px;
  font-size: clamp(0.9rem, 1.0vw + .1rem, 1.1rem);
  font-family: "Lexgend-Regular";
  user-select: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.leftfragment .borderbtn{
  margin-bottom: 25px;

}

.borderbtn{
  cursor: pointer;

}

span{
  color: var(--main-color);
}
button{
  height: fit-content;
  cursor: pointer;
  background-color: var(--main-color);
  padding: 15px;
  border-radius: 15px;
  font-size: clamp(1rem, 1.25vw + .1rem, 1.7rem);
  font-family: "Lexgend-Bold";
  border: 0;
  margin: auto 0;
  transition: 0.2s;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}


button.buy, .shine{
  display: inline-block;
  position: relative;
  -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.85) 30%, #000 50%, rgba(0,0,0,.85) 70%);
    -webkit-mask-size: 200%;
  animation: shine 2s linear infinite;
}

@keyframes shine {
  from { -webkit-mask-position: 150%; }
  to { -webkit-mask-position: -50%; }
}



button:hover{
  background-color: white;
  color: var(--main-color);

}


.price{
  display: flex;
  gap: 0px 20px;
}
@media (max-width: 880px) {
  .price{
    flex-direction: column-reverse;
    
    
  }

  .price .descP{
    margin-bottom: 10px;
  }
  .price button{
    width: fit-content;
  }

  img.product{
    width: clamp(25rem, 40vw, 35rem);
    max-width: 100%;

  }

}

.FAQContainer .leftfragment p{
width: 100%;
}
.FAQContainer .leftfragment{
  margin-left: 0;
}
.FAQContainer {
  width: 90%;

  margin: clamp(40px, 10vh, 100px) auto;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 40px 100px;
}
.FAQContainer > div{
  flex: 1 0 40%;
}

.centerbloc{
  width: 100%;
  text-align: center;
  margin: clamp(40px, 20vh, 150px)  0px clamp(40px, 20vh, 200px) 0px ;
}
.contact{
  width: 85%;
  margin: auto;
  padding: 40px 0px 100px 0;
}

.row{
  width: clamp(300px, 100% ,850px);
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  gap: 70px;
}

.loadingpage{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: var(--bg-color);
  z-index: 100;
  display: flex;
}
.loadingpage > div{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.loadingpage *{
  user-select: none;
  color: transparent;
}


.loadingpage svg{

  display: block;
  width: clamp(40px ,50vw, 70%);
 
}
.loadingpage.out{
  animation: out 0.5s ease-in forwards;


}

#rect {
  transition: all 0.4s;
  transform: translateX(var(--progress));
}

@keyframes slideOver {
  0% {
    transform: translateX(0);
  }
 70% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
  
}


@keyframes out {
from{
  opacity: 1;
}
  to{
    opacity: 0;
  }
}


.logspage{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;

}

.logspage > div:nth-child(1){
  flex: 1 0 40%;
  max-height: 100vh;
  overflow: auto;
  min-width: fit-content;
}



.logspage > div:nth-child(2){
  width: 60%;
  position: relative;
  background-color: var(--block-bg-color);
}

.paymentPage > div:nth-child(1){
width: 60%; 
 max-height: 100vh;
  overflow: auto;
  min-width: fit-content;
}

.paymentPage > div:nth-child(2){
  flex: 1 0 40%;
  position: relative;
  background-color: var(--block-bg-color);
}

.logspage .illustration-container > div{
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: center;
  margin: auto;
  min-height: 100%;
  position: relative;
  width: 80%;
}


@media (max-width: 880px) {
.firstFragment{
    display: flex;
    flex-wrap: wrap-reverse;
    position: relative;
  }
 .firstFragment .leftfragment{
    min-width: 300px;
  }

.firstFragment .rightfragment{
    display: flex;
    width: 100%;
    justify-content: center;
  }
 

   .firstFragment .price{
    position: absolute !important;
    top: 200px;
    z-index: 1;
  }

}
@media (max-width: 920px) {
  


  .Home .leftfragment, .ebook .leftfragment{
    width: 100%;
    margin: 0;
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .Home .leftfragment p, .ebook .leftfragment p{
    width: 100%;
  }

  .ebook .leftfragment{
    margin-bottom: 100px;
  }
}

.leftfragment .row{
  margin: 0;
  gap: 10px 25px ;
  flex-wrap: wrap;
align-items: center;
}

#responsive:not(.forget #responsive,.popUp #responsive){
  display: none;
}

.forget #responsive{
  width: 100%;
  display: flex;
  gap: 10px;
      align-items: center;
      padding-bottom: 10px;

}
@media  (max-width: 890px){
  .logspage > div:nth-child(2){
    display: none;
  }
  #responsive{
    display: flex !important;
gap: 10px;
    align-items: center;
    padding-bottom: 10px;

  }

 
}
@media  (max-width: 680px) {
  .App{
    width: 100%;
  }
  .fragment{
    width: 90%;
    margin: auto;
  }
  .Toastify__toast{
    width: 95% !important;
    margin: auto !important;
    bottom: 75vh !important;
}
}


/* TOASTS */

.Toastify__toast-container--bottom-right {
  z-index: 1000000000!important;
}

.Toastify__toast--error,
.Toastify__toast--success,
.Toastify__toast--info,
.Toastify__toast{
  z-index: 1000!important;
  border-radius: 10px !important;
  font-size: var(--little-font)!important;
  background: var(--block-bg-color) !important;
  color: var(--text-color) !important;
}


.toastProgress {
  background: var(--main-color) !important;
}


.user .row{
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.user .row > div{
  display: flex;
  flex-wrap: wrap;
  gap: 0px 50px ;
  align-items: center;
}

.user .row h3{
  display: flex;align-items: center;
  gap: 10px;
}

.contact.user form{
  margin-top: 50px;
  width: clamp(300px, 100% ,700px) ;
}

.contact.user form.changepwd .fields{
  gap: 0px;
}
.user form .borderbtn{
  width: fit-content;
margin-left: 0;
}
.user form .row{
  margin-top: 0px;
  align-items: center;
}
.user form .row button{
  margin: 0;
}
.user .orders{
  margin-top: 100px;
}


.strongpassword{
  width: 100%;


}

.strongpassword .progress{
  height: 0px;
  transition: all 0.3s;
  width: 0%;
  background-color: #D13232;

}

.progress.bad{
  height: 10px;
  width: 33%;
  background-color: #D13232;
}
.progress.ok{    height: 10px;

  width: 63%;
  background-color: #F19829;
}

.progress.good{    height: 10px;

  width: 100%;
  background-color: #26C318;
}

.user svg path:nth-child(2){
  opacity: 0.3  !important;
}


.loadercontainer{
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60vh;
}

.loadercontainer svg{
  width: fit-content;
}
.verifycontainer{
  width: clamp(300px, 100% ,700px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.verifycontainer h4{
  display: flex;
  align-items: center;
  gap: 10px;
}

.verifycontainer ion-icon{
  font-size: 20px;
}

ion-icon[name="checkmark-circle"]{
  color: #29FC06;
}

ion-icon[name="alert-circle"]{
  color: #E34040;
}

.paymentPage .form-container form{
  width: 80%;
  margin: auto;
}

.notfound{
  width: 100%;
  height:clamp(400px, 60vh, 1000px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.about{
  position: relative;
  padding-bottom: 50px;
  overflow: hidden;
  padding-bottom: 200px;
}
.about > .ligne{
  width: 30px;
  height: 0%;
  position: absolute;
  background-color:var(--block-bg-color);
 height: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  display: flex;
  justify-content: center;
}


.ligne > div{
  position: relative;
  width: 4px;
  background-color: var(--main-color);
;z-index: 1;
  border: 1px solid var(--bg-color);
height: var(--about);
transition: all 1s ease-in-out;
}

.about .rowSection,.about .rowSection .centered-bloc{
  margin-top: 100px;

}


.text-bloc{
  width: clamp(400px, 50vw, 850px);
}

.text-bloc h1{
  margin-bottom: 25px;
  width: fit-content;

}
.text-bloc h3, .centered-bloc h3{
  opacity: 0.52;
}
.about > h1{  

  width: fit-content;
  display: block;
  margin: auto;
  text-align: center;
  padding: 25px;
  padding-bottom: 0;
  border-radius: 10px 10px 0 0;
 transform: scale(1.05);
 margin-top: 50px;
}

.about >h1 {  text-transform:uppercase; }
.about > h1:after {
  display:block;
  content: '';
  padding-top: 25px;
  border-bottom: solid 3px var(--main-color);  
  transform: scaleX(0);  

  animation: border 1s ease-in-out forwards;
}
@keyframes border {
  from{
    transform: scaleX(0);  

  }to{
    transform: scaleX(1);  

  }
}
.about .rightfragment{
  position: relative;
  margin-left: auto;
  right: 50px;

}
.about .rowSection{
  padding: 40px 0;
}

.imagecontainer, .imagecontainer > img{
  width: clamp(314px, 30vw, 514px);
  height: clamp(319px, 32vw, 519px);
  object-fit: cover;
  border-radius: 10px;    
}

.imagecontainer{
  position: relative;
}
.imagecontainer > .support{
  display: flex;
  justify-content: center;
  top: -5%;right: -10%;
  border-radius: 10px;
  z-index: -1;
  width: 70%;
  height: 110%;
  position: absolute;
  background-color: var(--block-bg-color);box-shadow: 0px 2px 4px 0px var(--block-bg-color);

}

.leftfragment .imagecontainer >   .support{
right: auto;
left: -10%;
align-items: center;
}


.support > .ligne{
  width: 20px;
  height: 100%;
  background-color: var(--main-color);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--bg-color);

}
.leftfragment .imagecontainer >   .support > .ligne{
  height: 20px;
  width: 100%;
}

.centered-bloc{  

  width: clamp(400px, 70%, 1000px);
  display: block;
  margin: auto;

  background-color: var(--block-bg-color);
  border-radius: 0px 0px 10px 10px;
  margin-top: 150px;
}

.about .rowSection{
  flex-wrap: wrap;

}

.about .rowSection .text-bloc2{
  width: clamp(400px, 35vw, 850px);
  margin-left: auto;

}
.about .rowSection .text-bloc2 .text-bloc{
  width: 100%;
}
.about .rowSection .text-bloc2{  right: 0;
}
.text-bloc p, .text-bloc2 p{
  width: 100%;
}

.objectif{
  position: relative;
  width: 100%;
  height: 237px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("./assets/franck1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
}


.objectif h1{
  z-index: 1;
}
.centered-bloc p, .centered-bloc h3{

padding: 45px;
padding-bottom: 10px;

}
@media (max-width: 920px) {

  
  .about .rowSection .text-bloc2 .text-bloc{
    width: auto;
  }
  
  .about .rightfragment{
    right: 0;
  }
  .about .rightfragment{
    margin: auto;
    margin-top: 50px;
  }
.imagecontainer{
  margin: auto;
}
.row2{
  flex-direction: column-reverse;
}
.row2 .leftfragment{
  margin-top: 75px;

}
  .about .rightfragment.text-bloc2{
width: 100%;
margin: 0;
margin-bottom: 25px;
margin-top: 25px;  }
.text-bloc, .centered-bloc, .text-bloc2 > .text-bloc {
  width: 90%;
  margin: auto; 

}
.row2 > .rightfragment{
  width: 100%;
}


.text-bloc{background-color: var(--block-bg-color);
  padding: 15px;
  border-radius: 10px;
}
 .centered-bloc{
  width: fit-content
}
.text-bloc p, .text-bloc2 > .text-bloc p {
  width: 100%;
}

.about .rowSection{
margin-top: 20px;
}

.centered-bloc {
  margin-top: 60px;
}

.about{
  padding-bottom: 100px;
}
}

.mentions h1{
  margin-top: 50px;
border-bottom: 3px solid var(--main-color); padding-bottom: 15px; }

.mentions {
  width: 90%;
  margin: auto;
  padding-bottom: 100px;
}

.free-input{
  width: 100%;
  background-color: var(--block-bg-color);
  border: 1px solid var(--border-color);
  padding: 10px;
  box-sizing: border-box;
  padding-left: 2%;
  padding-right: 0;
  resize: none;
  margin-bottom: 20px;
  font-size: 1rem;
}