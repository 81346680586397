.admin{
    margin-top: 50px;
}
.admin header{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
margin-top: 50px;}

.admin header h4{
    cursor: pointer;
    padding-bottom: 10px;
    transition:  0.3s;
}
.admin header h4.selected{
    border-bottom: 3px solid var(--main-color);
}

.screenform .field{
    flex-direction: row;
    gap: 10px;
}

.info{
    position: relative;
}


.admin select{
    
}
.dynamic > div{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px; margin-bottom: 20px;
}
 .info:hover>.deletebg {
            opacity: 0.8;
        }
        
        .deletebg {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            z-index: 1;
            opacity: 0;
            background: rgba(117, 0, 0, 1);
            border-radius: 10px;
            transition: all 0.3s;
            top: 0;
            left: 0;
        }
        
        .deletebg::after {
            content: '+';
            position: absolute;
            font-size: 25px;
            top: 50%;
            left: 50%;
            color: white;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        .admin-comments{
            display: flex;
            flex-wrap: wrap;
            gap: 50px;
        }

        .admin-comments .selectComments{
            width: 15%;
        }

        .admin-comments > .container{
            width: 80%;
            max-height: 700px;
            overflow: auto;
        }

        .comments-visibles, .comments-unvisibles{
            display: flex;
            flex-direction: column;
            gap: 50px;
        }

        .selectComments .borderbtn{
            transition: all 0.3s;
        }
        .selectComments .borderbtn.selected{
            background-color: var(--main-color);

        }


        .comments-unvisibles .comment,.comments-visibles .comment{
            position: relative;
        }
        .onHoverInputs{
            opacity: 0;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background: rgba(24, 24, 27, 0.92);
            transition: all 0.2s;
        }

        .comments-unvisibles .comment:hover .onHoverInputs,.comments-visibles .comment:hover .onHoverInputs{
            opacity: 1;
        }

        .onHoverInputs > *{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            width: 50%;
            height: 100%;
            transition: all 0.2s;
        }

        .onHoverInputs h4:nth-child(1):hover{
            background: rgba(41, 252, 6, 0.60);
        }

        .onHoverInputs h4:nth-child(2):hover{
            background: rgba(227, 64, 64, 0.60);
        }
