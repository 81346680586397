.form-container > div{
   
    align-items: center;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: center;
    margin: auto;
    min-height: 100%;
    position: relative;
    width: 70%;


}

.form-container form{
    padding: 100px 0;
    width: 100%;
}

.form-container div form h1 {
    font-size: 24px;
}
.form-container button{
    width: 100%;
    margin-top: 35px;
}
.form-container form > *{
    font-size: 1rem;

}

.form-container form .fields{
    margin-top: 80px;
    gap: 40px;
    margin-bottom: 20px;
    
}

.form-container form input{
    font-size: 1rem;
}

.forget{
    width: fit-content;
    display: block;
    margin-left: auto;
}

.form-container form span:not(.google span){
    font-family: "Lexgend-Regular";
    font-size: 1rem;
    cursor: pointer;
    color: var(--text-color);
    border-bottom: 3px solid var(--main-color);

}

.forget form{
    width: clamp(400px, 40%, 500px)
}

.google{
    margin-top: 15px;
    
}


